<template>
  <div>
    <v-layout row wrap style="margin: 0; margin-bottom: 10px">
      <v-flex>
        <h1>Add client</h1>
      </v-flex>
    </v-layout>
    <v-form @submit="addClient">
      <v-text-field label="Client name" v-model="client_name"></v-text-field>
      <v-btn type="submit">Add</v-btn>
    </v-form>
  </div>
</template>

<script>
import { storage } from "../schmucklicloud";
export default {
  data() {
    return {
      client_name: "",
    };
  },
  methods: {
    async addClient(e) {
      e.preventDefault();

      var enterprise_id = window.localStorage.getItem("enterprise");

      if (
        enterprise_id === undefined ||
        Number.isNaN(parseInt(enterprise_id))
      ) {
        this.$notify.toast("Please select first an enterprise, before you create a client.")
      } else {
        var response = await storage.insert("clients", {
          enterprise_id: enterprise_id,
          name: this.client_name,
        });

        if (response.isOK) {
          this.$notify.toast("The client has been added.");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    },
  },
};
</script>

<style>
</style>